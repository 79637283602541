import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import Breadcrumbs from "../components/breadcrumbs";
import ContactDetailsDoctor from "../components/contact/contact-detail-doctor";
import ContactDetails from "../components/contact/contact-details";

type QueryType = {
  secretaryPhoto: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  site: {
    siteMetadata: {
      siteUrl: string;
    };
  };
};

const ContactPage = () => {
  const {
    secretaryPhoto: {
      childImageSharp: { gatsbyImageData: secretaryPhoto },
    },
    site: {
      siteMetadata: { siteUrl },
    },
  }: QueryType = useStaticQuery(query);

  return (
    <div className="w-full">
      <div className="relative w-full border-b h-140 bg-beige border-b-red-accent">
        <div className="pt-6 container-7xl">
          <Breadcrumbs />
          <GatsbyImage image={secretaryPhoto} alt="Sekretariat" />
        </div>
        <div className="flex w-full py-4 bg-white md:bottom-0 md:absolute bg-opacity-90">
          <div className="flex flex-wrap items-end justify-between container-7xl md:flex-nowrap">
            <p className="order-3 w-full mt-2 text-3xl text-left text-red-accent md:w-auto md:text-center md:order-2 font-extralight md:mt-0">
              Kontakt / Sekretariat
            </p>
            <p className="order-1 text-slate-500">Frau Jerate-Höhn</p>
            <p className="order-2 md:order-3 text-slate-500">
              Frau Malchartzeck
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-8 py-6 mx-auto container-7xl">
        <div className="grid items-center w-full grid-cols-12 gap-6">
          <div className="col-span-12 md:col-span-6">
            <ContactDetails />
          </div>
          <div className="col-span-12 md:col-span-6 h-96">
            {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8953.57643648872!2d6.851997726023691!3d51.238288925027454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b8c95163074ef1%3A0x349b9e7eda0711e1!2sBenderstra%C3%9Fe+76%2C+40625+D%C3%BCsseldorf!5e0!3m2!1sen!2sde!4v1438370814917"
              className="w-full h-full rounded-lg"
            /> */}
            {/* <iframe
              src="https://www.uwp.is.ed.ac.uk/3rd-party-widgets/maps/v4/embeds/gm.php?map=OAxYIUUw5f"
              className="w-full h-full rounded-lg"
            /> */}
            <iframe
              className="w-full h-full rounded-lg"
              src={`${
                process.env.NODE_ENV === "production"
                  ? siteUrl
                  : "http://localhost:8000"
              }/map.html`}
            />
          </div>
        </div>
        <hr />
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6 md:col-span-4">
            <ContactDetailsDoctor doctor="dr_soljan" />
          </div>
          <div className="col-span-12 sm:col-span-6 md:col-span-4">
            <ContactDetailsDoctor doctor="dr_salamon" />
          </div>
          <div className="col-span-12 sm:col-span-6 md:col-span-4">
            <ContactDetailsDoctor doctor="dr_schoettes" />
          </div>
        </div>
        <hr />
        <div className="flex flex-col gap-2">
          <p className="text-sm">
            Wir arbeiten ausschließlich nach individueller Terminvergabe.
            Zugelassen für alle privaten und allgemeinen Krankenkassen.
          </p>
        </div>
      </div>
    </div>
  );
};

const query = graphql`
  query ContactPageTemplateQuery {
    secretaryPhoto: file(relativePath: { eq: "sekretariat_02.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1600
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 80 }
        )
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export default ContactPage;
